@import "css/colors.scss";
@import "css/theme";

.container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.dialContainer {
  position: relative;
}

.dialFace, .dialHandle, .gauge {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.dialFace {
  width: 100%;
}

$dial-size: 40px;

.dialHandle {
  cursor: pointer;
  width: $dial-size;
  height: $dial-size;
  /* Use margin to make positioning via top/left easier */
  margin-left: calc(#{$dial-size} / -2);
  margin-top: calc(#{$dial-size} / -2);
}

.gaugePath {
  //fill: $motion-transparent;
  //stroke: $motion-primary;
  @include theme {
    stroke: getVar("primary_color");
    fill: getVar("primary_active_color");
  }
  stroke-width: 1px;
}
