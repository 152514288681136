@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme.scss";

.modalContent {
  width: 360px;
}

.body {
  background: $ui-white;
  padding: 1.5rem 2.25rem;
}

.label {
  font-weight: 500;
  margin: 0 0 0.75rem;
}

.minInput, .maxInput {
  margin-bottom: 1.5rem;
  width: 100%;
  //border: 1px solid $ui-black-transparent;
  border-radius: 5px;
  padding: 0 1rem;
  height: 3rem;
  //color: $text-primary-transparent;
  font-size: .875rem;
  @include theme {
    border: 1px solid getVar("text_quaternary_color");
    color: getVar("text_color")
  }
}

.buttonRow {
  font-weight: bolder;
  text-align: right;
}

.buttonRow button {
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  background: white;
  //border: 1px solid $ui-black-transparent;
  @include theme {
    border: 1px solid getVar("text_quaternary_color");
  }
  font-weight: 600;
  font-size: 0.85rem;
}

.buttonRow button.okButton {
  //background: $motion-primary;
  //border: $motion-primary;
  color: white;
  @include theme {
    border: getVar("primary_color");
    background: getVar("primary_color")
  }

}

[dir="ltr"] .buttonRow button + button {
  margin-left: 0.5rem;
}

[dir="rtl"] .buttonRow button + button {
  margin-right: 0.5rem;
}
