@import "css/theme.scss";

.trainPop {
  box-sizing: border-box;
  min-width: 558px;
  max-width: 90vw;
  min-height: 670px;
  max-height: 90vh;
  position: absolute;
  top: 70px;
  left: 200px;
  padding: 0 10px 10px;
  z-index: 4443;
  background: #fff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 1px 8px 0px #555;
  overflow: hidden;
  border-radius: 3px;
  cursor: -webkit-grab;
  animation: slowlyShow .5s;

  img {
    vertical-align: middle;
    border-style: none;
  }
}

.ml5Title {
  font-weight: bold;
  font-size: 16px;
  display: block;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
  padding: 10px;
  color: #fff;
  pointer-events: none;
  width: calc(100% + 20px);
  margin-left: -10px;
  box-sizing: border-box;
  text-indent: 15px;

  @include theme {
    background-color: getVar("primary_color");
  }
}

.modalBody {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex: 1;
  padding: 20px 15px;
  cursor: default;
  box-sizing: border-box;
}

.modalContent {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  box-sizing: border-box;
}

.modalBodyContainer {
  margin: 10px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    @include theme {
      background-color: lighten(getVar("primary_color"), 10%);
    }
  }
}

.modalContentLeft {
  flex: 3;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 10px -3px #333;
  max-width: 700px;
  min-width: 558px;
  min-height: 670px;
  max-height: 700px;
  box-sizing: border-box;
}

.modalContentRight {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 10px -3px #333;
  margin-left: 10px;
  width: 400px;
  box-sizing: border-box;
}


.rightIcon {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 500;
}

.iconLabel {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  transition: .2s;
  box-sizing: border-box;
}

.iconLabel:hover {
  opacity: .9;
}

.iconOrigin {
  width: 27px;
  margin-left: -5px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  margin-top: -3px;
  box-sizing: border-box;
  vertical-align: middle;
}

.iconSelected {
  opacity: .5;
  box-shadow: 0 0 13px -2px #0c0c0c;
}

.icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  transition: .2s;
  box-sizing: border-box;
}

.modalContent {
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
}

.imgLabel {
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.imgLabelSelect {
  font-weight: bold;

  @include theme {
    color: getVar("primary_color");
    border-left-color: getVar("primary_color");
  }
}

.img {
  max-width: 100%;
}

.imgsCon {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #CCBBBB;
  margin-bottom: 10px;
  padding-bottom: 9px;
  box-sizing: border-box;
}

.imgclassContainer {
  flex: 1;
  padding: 15px 5px 5px 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.imgclassContainerSelect {
  border-radius: 5px;

  @include theme {
    box-shadow: inset 0 0 31px -2px getVar("primary_box_shadow_color");
  }
}

.imgclassItem {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  height: 150px;
  overflow: auto;
}

.modelImgItem {
  width: 180px;
  height: 120px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.modelImgItem:hover+.deleteImgIcon {
  display: block;
}

.deleteImgIcon {
  position: absolute;
  transition: .5s;
  cursor: pointer;
  top: 0;
  right: 10px;
  padding: 10px;
  display: none;
  box-sizing: border-box;
}

.deleteImgIcon img {
  width: 110%;
}

.deleteImgIcon:hover {
  opacity: .8;
  display: block;
}

.rightTop {
  flex: 1px;
  padding: 20px;
  box-sizing: border-box;
}

.btnClass {
  flex: 1;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-direction: column;
  display: flex;
}

.ml5Btn {
  padding: 5px 20px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
  margin: 5px;
  color: #fff;
  min-height: 35px;
  transition: .5s;
  box-sizing: border-box;

  @include theme {
    background-color: darken(getVar("primary_color"), 5%);
    border: 1px solid darken(getVar("primary_color"), 5%);
  }
}

.ml5Btn:hover {
  opacity: .8;
}

.lightText {
  color: #F8AD31;
  min-width: 40px;
  display: inline-block;
  padding-left: 10px;
  box-sizing: border-box;

  @include theme {
    color: lighten(getVar("primary_color"), 20%);
  }
}

.icon img {
  width: 100%;
}

.icon:hover {
  box-shadow: 0 0px 0px 3px #f1eded9c;
  transform: scale(1.1);
}

.videoContainer {
  border-radius: 5px;
  overflow: hidden;
}

.displayBlock {
  display: block !important;
}

.smallCircle {
  width: 7px;
  height: 7px;
  background: red;
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelInput {
  min-width: 120px;
  max-width: 150px;

  @include theme {
    border: 1px solid getVar("primary_color");
  }

  outline: none;
  box-shadow: none;
  background: none;
  font-size: 15px;
  border-radius: 5px;
  margin-left: 10px;
}


.labelInput:focus {
  @include theme {
    box-shadow: 0 0 2px 1px getVar("primary_box_shadow_color");
    border-color: getVar("primary_border_focus_color");
  }
}

.emptyTip {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.tipForNum {
  margin-left: 10px;
  font-size: 13px;
  color: #7B7777;
  position: absolute;
  right: 40;
  top: 3;
  letter-spacing: 1px;
}

.collapseBtn {
  position: absolute;
  right: 0;
  top: 70px;

  @include theme {
    background-color: getVar("primary_color");
  }

  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.collapseBtn.collapsed {
  border-radius: 0 5px 5px 0;
  transform: scale(-1, 1);
}

@keyframes slowlyShow {
  0% {
    opacity: 0;
    margin-top: -40px;
  }

  20% {
    opacity: 0;
  }

  70% {
    opacity: .7;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}
