@import "css/theme.scss";

.container {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  @include theme {
    color: getVar("text_secondary_color");
    background-color: getVar("container_bg_color");
    border: 1px solid getVar("border_color")
  }

  &:hover {
    @include theme {
      border-color: getVar("primary_border_hover_color")
    }
  }
}
