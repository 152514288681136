@import "css/theme.scss";

.draggingSprite {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  filter: drop-shadow(5px 5px 5px hsla(0, 0%, 0%, 0.15));
}

.stageOverlays {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  /* the overlay itself should not capture pointer events; only its child elements can do that */
  pointer-events: none;

  &.withColorPicker {
    cursor: none;
    z-index: 2010;
  }
}

.monitorWrapper {
  pointer-events: none;
}

.stage {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.withColorPicker {
    cursor: none;
    z-index: 2010;
  }
}

.stageFullScreen {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorPickerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.colorPickerBackground {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: block;
  z-index: 2000;
}
