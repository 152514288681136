@import "css/theme.scss";

.container {
  display: flex;
  cursor: pointer;
  transition: all 0.5s;
  flex-direction: column;

  span {
    width: 36px;
    height: 36px;
    @include theme {
      background-color: lighten(getVar("primary_color"), 30%);
    }
    margin-bottom: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      /* 针对截图svg g path */
      g {
        path {
          @include theme {
            stroke: getVar("primary_color")
          }
        }
      }
      /* 针对截图svg path,颜色改为主题色*/
      path {
        @include theme {
          fill: getVar("primary_color")
        }
      }
    }
  }
}
