@import "css/colors";
@import "css/units";

.modalContent {
    width: 600px;
}

.body {
    background: $ui-white;
    padding: 1.5rem 2.25rem;
}

.visualizationContainer {
    display: flex;
    justify-content: space-around;
}

.meterContainer, .waveformContainer {
    background: $ui-primary;
    border: 1px solid $ui-black-transparent;
    border-radius: 5px;
    padding: 3px;
    /* Force these to be on their own render layer because they update often */
    transform: translateZ(0);
}

.meterContainer {
    margin-right: 5px;
    height: 180px;
}

.waveformContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 180px;
    position: relative;
}

.helpText {
    margin: 10px auto 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(167, 170, 181);
    font-size: 0.95rem;
    font-weight: 500;
}

.playingText {
    color: $looks-secondary;
}

.recordingText {
    color: $red-primary;
}

.mainButtonRow {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.mainButtonRow button {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background: transparent;
    border: none;
}

.mainButtonRow button:disabled {
    opacity: 0.25;
}

.mainButtonRow button:active, .mainButtonRow button:focus {
    outline: none;
}

.buttonRow {
    font-weight: bolder;
    text-align: right;
    display: flex;
    justify-content: space-between;
}

.buttonRow button {
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    background: white;
    border: 1px solid $ui-black-transparent;
    font-weight: 600;
    font-size: 0.85rem;
    color: $looks-secondary;
}

.buttonRow button.okButton {
    background: $looks-secondary;
    border: $looks-secondary;
    color: white;
}

.buttonRow button + button {
    margin-left: 0.5rem;
}

.mainButton {
    text-align: center;
}

/* Increase specificity to make sure this wins out over normalize.css */
svg.recordButton {
    overflow: visible;
}

.recordButtonCircle {
    fill: $red-primary;
    stroke: $red-tertiary;
}

.recordButtonCircleOutline {
    fill: $red-primary;
    opacity: 0.2;
    transition: 0.1s;
}

[dir="rtl"] .rerecordButton img {
    transform: scaleX(-1);
}
