@import "css/colors.scss";

.container {
    width: 100%;
}
.waveformPath {
    /*
        This color is lighter than sound-primary, but
        cannot use alpha because of overlapping elements.
    */
    fill: hsl(300, 54%, 72%);
    stroke: $sound-tertiary;
}
.baseline {
    stroke: $sound-tertiary;
}
