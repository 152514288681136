// 状态颜色的初始map
$status: (
  success: #2ecc71,
  warning: #f1c40f,
  info: #1677ff,
  error: #e74c3c
);

// 扩展状态颜色map
$status-colors: (
  success_border_color: #b7eb8f,
  success_border_hover_color: #95de64,
  warning_border_color: #f7d674,
  warning_border_hover_color: #f5c247,
  info_border_color: #94c5f5,
  info_border_hover_color: #6da8f2,
  error_border_color: #f9c7c5,
  error_border_hover_color: #f7b1b0
);

// 动态生成颜色map
@each $status-key, $status-value in $status {
  // 合并每个状态的颜色映射到全局的映射中
  $status-colors: map-merge($status-colors,
      (#{$status-key}_color: $status-value,
        #{$status-key}_active_color: darken($status-value, 10%), // 变暗
        #{$status-key}_hover_color: lighten($status-value, 15%), // 变亮
      ));
}

// 公共颜色合并进map
$common-colors: map-merge($status-colors ,
    ( //文本色
      white_text_color:#fff, //白色
      text_color: #000, //黑色
      text_secondary_color: #616161, //次级文本
      text_tertiary_color: #666, //三级文本
      text_quaternary_color:#aaa, //四级文本
      container_bg_color: #fff, //容器背景色
      container_bg_hover_color: #f5f5f5, //容器背景悬浮态
      border_color: rgba(0, 0, 0, 0.15), //容器边框色
      border_secondary_color: #f0f0f0, // 容器边框次级色)
      mask_background_color: rgba(0, 0, 0, 0.45) // 蒙层颜色
    ));

$themes: (
  blue: map-merge($common-colors, (primary_color: #3498db, //primary
      primary_background_color: #e8edf1, //主色浅色背景色
      primary_background_hover_color: #e0f7ff, //主色浅色背景悬浮态
      primary_border_color:#b8eaff, //主色边框
      primary_border_hover_color:#89d1f5, //主色边框悬浮态
      primary_border_focus_color:#3498db, //主色边框激活态
      primary_box_shadow_color:#3498db4d, //主色盒子阴影
      primary_hover_color:#5db5e8, //主色悬浮态
      primary_active_color:#2276b5, //主色激活态
      primary_disabled_color:#b8eaff, //主色禁用态
      primary_text_color:#3498db, //主色文字
      primary_text_hover_color:#5db5e8, //主色文字悬浮态
      primary_text_active_color:#2276b5, //主色文字激活态
      primary_light_transparent: hsla(215, 100%, 65%, 0.15), //主色浅色透明
      body_bg_color: #e8edf1,
      invert_text_color: #fff, // 反转的文字颜色
      scrollbar_color:hsla(215, 100%, 65%, 0.20),
    )),

  base: map-merge($common-colors, (primary_color: #f8ad30, //primary
      primary_background_color: #fff9f1, //主色浅色背景色
      primary_background_hover_color: #ffc859, //主色浅色背景悬浮态
      primary_border_color:#ffe9ab, //主色边框
      primary_border_hover_color:#ffc859, //主色边框悬浮态
      primary_border_focus_color:#f8ad30, //主色边框激活态
      primary_box_shadow_color:#f8ad304d, //主色盒子阴影
      primary_hover_color:#ffc859, //主色悬浮态
      primary_active_color:#A26A00, //主色激活态
      primary_text_color:#f8ad30, //主色文字
      primary_text_hover_color:#ffc859, //主色文字悬浮态
      primary_text_active_color:#d1891d, //主色文字激活态
      primary_light_transparent: #d3e62e26, //主色浅色透明
      body_bg_color: #fff9f1,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #ffe9ab, //主色禁用态
      scrollbar_color:hsla(32, 100%, 50%, 0.40),
    )),

  orange: map-merge($common-colors, (primary_color: #ff664e, //primary
      primary_background_color: #fef8f5, //主色浅色背景色
      primary_background_hover_color: #fff4f0, //主色浅色背景悬浮态
      primary_border_color:#ffd6c9, //主色边框
      primary_border_hover_color:#ffb4a1, //主色边框悬浮态
      primary_border_focus_color:#ff664e, //主色边框激活态
      primary_box_shadow_color:#ff664e4d, //主色盒子阴影
      primary_hover_color:#ff8e78, //主色悬浮态
      primary_active_color:#d94838, //主色激活态
      primary_text_color:#ff664e, //主色文字
      primary_text_hover_color:#ff8e78, //主色文字悬浮态
      primary_text_active_color:#d94838, //主色文字激活态
      primary_light_transparent: rgba(248, 173, 48, 0.15),
      body_bg_color: #fef8f5,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #ffd6c9, //主色禁用态
      scrollbar_color:rgba(255, 102, 78, 0.5),
    )),

  red: map-merge($common-colors, (primary_color: #200a16, //primary
      primary_background_color: #fef8f5, //主色浅色背景色
      primary_background_hover_color: #fab4d1, //主色浅色背景悬浮态
      primary_border_color:#ed85b4, //主色边框
      primary_border_hover_color:#e05a9b, //主色边框悬浮态
      primary_border_focus_color:#c61075, //主色边框激活态
      primary_box_shadow_color:#c610754d, //主色盒子阴影
      primary_hover_color:#d43386, //主色悬浮态
      primary_active_color:#a10560, //主色激活态
      primary_text_color:#c61075, //主色文字
      primary_text_hover_color:#d43386, //主色文字悬浮态
      primary_text_active_color:#a10560, //主色文字激活态
      primary_light_transparent: rgba(198, 16, 117, 0.15),
      body_bg_color: #fffbf7,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #ed85b4, //主色禁用态
      scrollbar_color:hsla(327, 42%, 83%, 0.80),
    )),

  green: map-merge($common-colors, (primary_color: #6bd9a9, //primary
      primary_background_color: #f2fbe0, //主色浅色背景色
      primary_background_hover_color: #f0fff6, //主色浅色背景悬浮态
      primary_border_color:#f0fff7, //主色边框
      primary_border_hover_color:#c4f2db, //主色边框悬浮态
      primary_border_focus_color:#6bd9a9, //主色边框激活态
      primary_box_shadow_color:#6bd9a94d, //主色盒子阴影
      primary_hover_color:#95e6c0, //主色悬浮态
      primary_active_color:#4fb38b, //主色激活态
      primary_text_color:#6bd9a9, //主色文字
      primary_text_hover_color:#95e6c0, //主色文字悬浮态
      primary_text_active_color:#4fb38b, //主色文字激活态
      primary_light_transparent: rgba(107, 217, 169, 0.15),
      body_bg_color: #f2fbe0,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #c4f2db, //主色禁用态
      scrollbar_color:hsla(157, 51%, 61.6%, 0.60),
    )),

  purple:map-merge($common-colors, (primary_color: #e3c1f8, //primary
      primary_background_color: #eff8fe, //主色浅色背景色
      primary_background_hover_color: #fbf0ff, //主色浅色背景悬浮态
      primary_border_color:#fbf0ff, //主色边框
      primary_border_hover_color:#faf0ff, //主色边框悬浮态
      primary_border_focus_color:#e3c1f8, //主色边框激活态
      primary_box_shadow_color:#e3c1f84d, //主色盒子阴影
      primary_hover_color:#faf0ff, //主色悬浮态
      primary_active_color:#ba99d1, //主色激活态
      primary_text_color:#e3c1f8, //主色文字
      primary_text_hover_color:#faf0ff, //主色文字悬浮态
      primary_text_active_color:#ba99d1, //主色文字激活态
      primary_light_transparent: rgba(227, 193, 248, 0.15),
      body_bg_color: #eff8fe,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #faf0ff, //主色禁用态
      scrollbar_color:hsla(277, 86%, 80%, 0.60),
    )),

  violet: map-merge($common-colors, (primary_color: #443256, //primary
      primary_background_color: #f5fdfc, //主色浅色背景色
      primary_background_hover_color: #87818a, //主色浅色背景悬浮态
      primary_border_color:#7a757d, //主色边框
      primary_border_hover_color:#6b6570, //主色边框悬浮态
      primary_border_focus_color:#443256, //主色边框激活态
      primary_box_shadow_color:#4432564d, //主色盒子阴影
      primary_hover_color:#574a63, //主色悬浮态
      primary_active_color:#241a30, //主色激活态
      primary_text_color:#443256, //主色文字
      primary_text_hover_color:#574a63, //主色文字悬浮态
      primary_text_active_color:#241a30, //主色文字激活态
      primary_light_transparent: rgba(68, 50, 86, 0.15),
      body_bg_color: #f5fdfc,
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #87818a, //主色禁用态
      scrollbar_color:hsla(270, 27%, 26%, 0.60),
    )),

  eye-protect: map-merge($common-colors, (primary_color: #efa62c, //primary
      primary_background_color: #dddddd, //主色浅色背景色
      primary_background_hover_color: #fff4d1, //主色浅色背景悬浮态
      primary_border_color:#ffe8a8, //主色边框
      primary_border_hover_color:#ffd980, //主色边框悬浮态
      primary_border_focus_color:#efa62c, //主色边框激活态
      primary_box_shadow_color:#efa62c4d, //主色盒子阴影
      primary_hover_color:#fcc556, //主色悬浮态
      primary_active_color:#c9831a, //主色激活态
      primary_text_color:#efa62c, //主色文字
      primary_text_hover_color:#fcc556, //主色文字悬浮态
      primary_text_active_color:#c9831a, //主色文字激活态
      primary_light_transparent: hsla(30, 100%, 50%, 0.25),
      body_bg_color: rgb(172, 160, 141),
      invert_text_color: #fff, // 反转的文字颜色
      primary_disabled_color: #ffe8a8, //主色禁用态
      scrollbar_color:hsla(38, 76%, 55%, 0.50),
    )),
);

// Mixin for themes
@mixin theme {
  @each $themes-key, $themes-map in $themes {
    $themes-map: $themes-map !global;

    [data-theme=#{$themes-key}] & {
      @content;
    }
  }
}

// 获取对应的主题数据
@function getVar($key) {
  @return map-get($themes-map, $key);
}
