@import "css/colors.scss";

.turboContainer {
  display: flex;
  align-items: center;
  user-select: none;
}

.turboIcon {
  width: 31px;
  height: 30px;

  &.turboIconSmall {
    width: 25px;
    height: 30px;
  }
}


.turboLabel {
  font-size: 16px;
  font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
  color: $control-primary;
  white-space: nowrap;
}
