@import "css/units.scss";
@import "css/colors.scss";

.wrapper {
    display: flex;
    flex-grow: 1;
    border: 1px solid $ui-black-transparent;
    background: white;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.85rem;
}

[dir="ltr"] .wrapper {
    border-top-right-radius: $space;
    border-bottom-right-radius: $space;
}

[dir="rtl"] .wrapper {
    border-top-left-radius: $space;
    border-bottom-left-radius: $space;
}

.detailArea {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: visible;
}

[dir="ltr"] .detailArea {
    border-left: 1px solid $ui-black-transparent;
}

[dir="rtl"] .detailArea {
    border-right: 1px solid $ui-black-transparent;
}
