@import "css/theme.scss";
@import "css/units.scss";
@import "css/z-index.scss";

.mainContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include theme {
    background-color: getVar('body_bg_color');
  }

  min-width: 1024px;
  min-height: 640px;
}

.header {
  height: $menu-bar-height;
  width: 100%;
  min-height: $menu-bar-height;
  max-height: $menu-bar-height;
  z-index: $z-index-menu-bar;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding: 0 8px 0 0;
  gap: 8px;
  overflow-y: hidden;
}


.blockWrapper {
  flex-grow: 1;
  flex-basis: 598px;
  flex-shrink: 0;
}
