@import "css/units.scss";

.targetPane {
    /* Makes columns for the sprite library selector + and the stage selector */
    display: flex;
    flex-direction: row;
    // flex-grow: 1;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12px;
}

.stageSelectorWrapper {
    display: flex;
    flex-basis: 72px;
    flex-shrink: 0;
}

[dir="ltr"] .stageSelectorWrapper {
    margin-left: calc(#{$space} / 2);
}

[dir="rtl"] .stageSelectorWrapper {
    margin-right: calc(#{$space} / 2);
}
