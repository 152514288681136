.monitorList{
    /* Width/height are set by the component, margin: auto centers in fullscreen */
    margin: auto;
    pointer-events: none;
    overflow: hidden;
}

.monitorListScaler {
    /* Scaling for monitors should happen from the top left */
    transform-origin: left top;
}

::-ms-clear { display: none; }
