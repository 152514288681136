@import "css/units.scss";
@import "css/colors.scss";

.inputGroup{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.inputGroupColumn{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputGroupColumn span {
    margin-bottom: .25rem;
}

.inputLabel, .inputLabelSecondary{
    font-size: 0.625rem;
    user-select: none;
    cursor: default;

    white-space: nowrap;
}

[dir="ltr"] .inputLabel, [dir="ltr"] .inputLabelSecondary{
    margin-right: .5rem;
}

[dir="rtl"] .inputLabel, [dir="rtl"] .inputLabelSecondary{
    margin-left: .5rem;
}

.inputLabel{
    font-weight: bold;
}
