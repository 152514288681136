@import "css/theme.scss";

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  flex-basis: 0;

  .stage {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controlBar {
    height: 44px;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .sprite {
    flex-grow: 1;
    width: 100%;
    padding-top: 8px;
    min-height: 0;
  }
}

.containerLarge {
  @extend .container;

}

.containerSmall {
  @extend .container;

}

.fullscreenStageWrapper {
  @extend .container;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);

  @include theme {
    background-color: getVar("container_bg_color");
  }

  z-index: 1000;

  .stage {
    height: unset;
    padding: 6px;
  }

  .controlBar {
    background-color: #e8edf1;
    flex-shrink: 0;
    height: 44px;

    @include theme {
      background-color: getVar("container_bg_color");
    }
  }
}
