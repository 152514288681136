@import "css/units.scss";
@import "css/colors.scss";
@import "css/theme";

$header-height: calc(#{$stage-menu-height} - 2px);

.stageSelector {
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* For the add backdrop button */
  flex-grow: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $ui-white;
  color: $text-primary;
  border-top-left-radius: $space;
  border-top-right-radius: $space;
  border-color: $ui-black-transparent;
  border-width: 1px;
  border-style: solid;
  border-bottom: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.stageSelector.isSelected {
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;

  @include theme {
    border-color: getVar("primary_color");
    box-shadow: 0 0 0 3px getVar("primary_box_shadow_color");
  }
}

.stageSelector:hover {

  //border-color: $motion-primary;
  @include theme {
    border-color: getVar("primary_color");
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $header-height;
  background-color: white;
  color: $text-primary;
  border-top-left-radius: $space;
  border-top-right-radius: $space;
  border-bottom: 1px solid $ui-black-transparent;
  width: 100%;
  transition: background-color 0.25s ease;
}

.headerTitle {
  font-size: 0.625rem;
  font-weight: bold;
  color: $text-primary;

  /* @todo: make this a mixin for all UI text labels */
  user-select: none;
  transition: color 0.25s ease;
}

.stageSelector.isSelected .header {

  //background-color: $motion-primary;
  @include theme {
    background-color: getVar("primary_color");
  }

  .headerTitle {
    color: #fff;
  }
}



.count {
  padding: 0.3rem 0.75rem;
  font-size: 0.625rem;
  color: $text-primary;
  user-select: none;
}

.label {
  margin: 0.75rem 0 0.25rem;
  font-size: 0.6rem;
  color: $text-primary;
  user-select: none;
  text-align: center;
}

.costumeCanvas {
  display: block;
  margin-top: .25rem;
  width: 72px;
  height: 49px;
  user-select: none;
  border: 1px solid $ui-black-transparent;
  border-radius: .25rem;
  box-shadow: inset 0 0 4px $ui-black-transparent;

}

.addButton {
  position: absolute;
  bottom: 0.75rem;
}

.raised,
.raised .header {
  background-color: $drop-highlight;
  transition: all 0.25s ease;
}

.raised:hover {
  transform: scale(1.05);
}

.receivedBlocks {
  animation: glowing 250ms;
}

@keyframes glowing {
  10% {
    box-shadow: 0 0 10px #7fff1e;
  }

  90% {
    box-shadow: 0 0 10px #7fff1e;
  }

  100% {
    box-shadow: none;
  }
}
