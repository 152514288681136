@import "css/theme.scss";

.container {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  @include theme {
    color: getVar("text_secondary_color");
  }
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  user-select: none;

  &.disabled {
    cursor: not-allowed;

    .checkbox {
      cursor: not-allowed;
      pointer-events: none;

      .checkboxInner {
        background: rgba(0, 0, 0, .04) !important;
        border-color: #d9d9d9 !important;

        &::after {
          border-color: rgba(0, 0, 0, 0.25)
        }
      }
    }

  }

  &::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }

  .checkbox {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    @include theme {
      color: getVar("text_secondary_color");
    }
    font-size: 14px;
    line-height: 1;
    list-style: none;
    white-space: nowrap;
    border-radius: 4px;
    align-self: center;
    cursor: pointer;

    .checkboxInput {
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      margin: 0;
    }

    .checkboxInner {
      box-sizing: border-box;
      position: relative;
      top: 0;
      display: block;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      transition: all .3s;

      &.checked {
        @include theme {
          background-color: getVar("primary_color");
          border-color: getVar("primary_border_color");
        }

        &::after {
          opacity: 1;
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
        }
      }

      &::after {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        inset-inline-start: 21.5%;
        display: table;
        width: 5.7px;
        height: 9px;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(0) translate(-50%, -50%);
        opacity: 0;
        content: "";
        transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
      }
    }
  }

  .checkboxLabel {
    padding: 0 8px;
  }
}
