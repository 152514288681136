@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme.scss";

.modalContent {
  width: 480px;
  line-height: 1.75;
}

.header {
  img {
    max-width: 30px;
  }
}

.body {
  background: $ui-white;
}

.label {
  font-weight: 500;
  margin: 0 0 0.75rem;
}

.centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.peripheralTilePane {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.peripheralTile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: $ui-white;
  border-radius: 0.25rem;
  padding: 10px;
  width: 100%;
  height: 55px;
  margin-bottom: 0.5rem;
}

.peripheralTileName {
  display: flex;
  align-items: center;

  img {
    max-width: 35px;
    background: gray;
  }
}

[dir="ltr"] .peripheralTileImage {
  margin-right: 0.5rem;

}

[dir="rtl"] .peripheralTileImage {
  margin-left: 0.5rem;
}

.peripheralTileNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.peripheralTileNameLabel {
  font-weight: bold;
  font-size: 0.625rem;
}

.peripheralTileNameText {
  font-size: 0.875rem;
}

.peripheralTile button {
  padding: 0.6rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.85rem;

  @include theme {
    background-color: getVar("primary_color");
    border: getVar("primary_border_color");
    color: getVar("invert_text_color")
  }

  cursor: pointer;
}

.signalStrengthMeter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 22px;
  height: 16px;
}

[dir="ltr"] .signalStrengthMeter {
  margin-right: 1rem;
}

[dir="rtl"] .signalStrengthMeter {
  margin-left: 1rem;
}

.signalBar {
  width: 4px;
  border-radius: 4px;
  background-color: #DBDBDB;
}

.signalBar:nth-of-type(1) {
  height: 25%;
}

.signalBar:nth-of-type(2) {
  height: 50%;
}

.signalBar:nth-of-type(3) {
  height: 75%;
}

.signalBar:nth-of-type(4) {
  height: 100%;
}

.greenBar {
  background-color: $pen-primary;
}

.radarSmall {
  width: 40px;
  height: 40px;
}

[dir="ltr"] .radarSmall {
  margin-right: 0.5rem;
}

[dir="rtl"] .radarSmall {
  margin-left: 0.5rem;
}

.radarBig {
  width: 120px;
  height: 120px;
}

.radarSpin {
  animation: spin 4s linear infinite;
}

[dir="ltr"] .radar {
  margin-right: .5rem;
}

[dir="rtl"] .radar {
  margin-left: .5rem;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.peripheralActivity {
  position: relative;
}

.peripheralActivityIcon {
  max-width: 100px;
}

.connectionTipIcon {
  position: absolute;
}

.bluetoothConnectingIcon {
  position: absolute;
  top: -5px;
  right: -15px;
  left: -15px;
  padding: 5px 5px;
  background-color: $looks-secondary;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 4px $looks-transparent;
  /* animation: pulse-blue-ring 1s infinite ease-in-out alternate; */
  animation: wiggle 0.5s infinite ease-in-out alternate;
  max-width: 30px;

}

@keyframes pulse-blue-ring {
  100% {
    box-shadow: 0px 0px 0px 8px $looks-light-transparent;
  }
}

.bluetoothConnectedIcon {
  position: absolute;
  top: -5px;
  right: -15px;
  left: -15px;
  padding: 5px 5px;
  background-color: $pen-primary;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 4px $pen-transparent;
}

@keyframes wiggle {
  0% {
    transform: rotate(3deg) scale(1.05);
  }

  25% {
    transform: rotate(-3deg) scale(1.05);
  }

  50% {
    transform: rotate(5deg) scale(1.05);
  }

  75% {
    transform: rotate(-2deg) scale(1.05);
  }

  100% {
    transform: rotate(0deg) scale(1.05);
  }
}

.bluetoothCenteredIcon {
  position: absolute;
  padding: 5px 5px;
  background-color: $looks-secondary;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 2px $looks-transparent;
}

.peripheralTileWidgets {
  display: flex;
  align-items: center;
}

.activityArea {
  height: 165px;
  background-color: $looks-light-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}

.scratchLinkHelp {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.scratchLinkHelpStep {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

[dir="ltr"] .scratchLinkHelpStep {
  margin-left: 2.5rem;
}

[dir="rtl"] .scratchLinkHelpStep {
  margin-right: 2.5rem;
}

.scratchLinkIcon {
  max-width: 50px;
}

.helpStepImage {
  max-width: 40px;
}

[dir="ltr"] .helpStepImage {
  margin-right: 0.5rem;
}

[dir="rtl"] .helpStepImage {
  margin-left: 0.5rem;
}

.helpStepNumber {
  background: $pen-tertiary;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ui-white;
  font-weight: bold;
  min-width: 2rem;
  height: 2rem;
}

[dir="ltr"] .helpStepNumber {
  margin-right: 0.5rem;
}

[dir="rtl"] .helpStepNumber {
  margin-left: 0.5rem;
}

.buttonRow {
  font-weight: bolder;
  text-align: center;
  display: flex;
}

.abortConnectingIcon {
  width: 10px;
  transform: rotate(45deg);
}

.connectionButton {
  padding: 0.6rem 0.75rem;
  border-radius: 0.5rem;

  @include theme {
    background-color: getVar("primary_color");
    color: getVar("invert_text_color");
  }

  font-weight: 600;
  font-size: 0.85rem;
  margin: 0.25rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.connectionButton:disabled {
  @include theme {
    background-color: getVar("primary_disabled_color");
  }
}

.segmentedButton {
  display: flex;
}

.segmentedButton .connectionButton:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

.segmentedButton .connectionButton:last-of-type {
  margin-left: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[dir="ltr"] .buttonIconRight {
  margin-left: 0.5rem;
}

[dir="rtl"] .buttonIconRight {
  margin-right: 0.5rem;
}

[dir="ltr"] .buttonIconLeft {
  margin-right: 0.5rem;
}

[dir="rtl"] .buttonIconLeft {
  margin-left: 0.5rem;
}

/* reverse back arrow icon for RTL, don't reverse other connection icons */
[dir="rtl"] .buttonIconBack {
  transform: scaleX(-1);
}

.redButton {
  background: $red-primary;
}

.cornerButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.bottomArea {
  background-color: $ui-white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

.bottomArea .bottomAreaItem+.bottomAreaItem {
  margin-top: 1rem;
}

.instructions {
  text-align: center;
}

.dotsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dotsHolder {
  display: flex;
  padding: 0.25rem 0.1rem;
  border-radius: 1rem;
  background: $looks-light-transparent;
}

.dotsHolderSuccess {
  background: $pen-transparent;
}

.dotsHolderError {
  background: $error-transparent;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.3rem;
  border-radius: 100%;
}

.inactiveStepDot {
  @include theme {
    background-color: getVar("primary_box_shadow_color");
  }
}

.activeStepDot {
  @include theme {
    background-color: getVar("primary_color");
  }
}

.successDot {
  @include theme {
    background-color: getVar("success_color");
  }
}

.errorDot {

  @include theme {
    background-color: getVar("error_color");
  }
}

.scratchLinkError {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  width: 90%;
}

.scratchLinkErrorDetails {
  display: flex;
  resize: none;
  height: 60%;
  width: inherit;
}
