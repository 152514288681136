@import "css/theme.scss";


.messageWrapper {
  width: 459px;
  padding-bottom: 27px;
  border-radius: 8px;
  box-sizing: border-box;
}

.progressMessageWrapper {
  display: inline;
  position: fixed;
  top: 200px;
  left: 700px;
  border: 1px solid #dbdbdb;
  z-index: 999999;
  box-shadow: 2px 2px 1px #E6E6E4;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.header {
  width: 100%;
  padding-left: 15px;
  box-sizing: border-box;
}

.headerItemClose {
  margin-left: 410px;
  padding-top: 5px;
  box-sizing: border-box;
}

.messageStrWrapper {
  box-sizing: border-box;
  width: 100%;
  padding-left: 25px;
}

.progressNumWrapper {
  width: 100%;
  padding-left: 18px;
  box-sizing: border-box;
}

.progress {
  padding-left: 18px;
  padding-top: 7px;
  width: 100%;
  height: 23px;
  box-sizing: border-box;
}

.progressBarWrapper {
  box-sizing: border-box;
  width: 409px;
  height: 16px;
  border-radius: 25px;
  background: #F1F1F1;
  z-index: 2000;
}

.progressBar {
  height: 100%;
  border-radius: 25px;
  z-index: 2001;
  position: relative;
  @include theme{
    background-color: getVar("primary_color");
  }
}

.writetatepan {
  color: #AAAAAA;
  font-size: 18px;
}

.writeProgressSpan {
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  font-size: 22px;
}

.modeWrapper {
  padding-left: 25px;
  color: #AAAAAA;
  font-size: 15px;
  box-sizing: border-box;
}
