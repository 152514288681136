@import "css/theme.scss";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 9px 0;
  font-size: 14px;
  column-gap: 12px;

  .total {
    @include theme {
      color: getVar("text_quaternary_color")
    }
  }

  .operator {
    font-size: 16px;
    font-weight: 600;

    @include theme {
      color: getVar("text_quaternary_color")
    }
  }

  button {
    @include theme {
      color: getVar("text_secondary_color")
    }

    box-sizing: border-box;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &.active {
      @include theme {
        border: 1px solid getVar("text_quaternary_color")
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .quickJumper {
    @include theme {
      color: getVar("text_quaternary_color");
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    input::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
    }

    input[type="number"] {
      appearance: textfield;
    }

    .pageInput {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      line-height: 32px;
      padding: 4px;
      margin-right: 4px;
      border-radius: 6px;
      outline: none;
      text-align: center;
      margin: 0 13px;

      @include theme {
        border: 1px solid getVar("text_quaternary_color");
        color: getVar("text_quaternary_color");
      }
    }
  }

}
