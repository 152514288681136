.containerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .stageControl {
    display: flex;
    //background-color: #46b639;
    justify-content: flex-start;
    gap: 8px;
  }

  .runWrapper {
    display: flex;
    //background-color: #1eaaff;
  }
}

