@import "css/colors.scss";
@import "css/units.scss";

$main-button-size: 2.75rem;

.modalContent {
  width: 552px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $ui-white;
  padding: 1.5rem 2.25rem;
}

.cameraFeedContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;

  background: $ui-primary;
  border: 1px solid $ui-black-transparent;
  border-radius: 4px;
  padding: 3px;

  width: 480px;
  height: 360px;
  position: relative;
  overflow: hidden;
}

.canvas {
  position: absolute;
  width: 480px;
  height: 360px;
}

.loadingText {
  position: absolute;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text-primary-transparent;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: center;
}

.helpText {
  margin: 10px auto 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text-primary-transparent;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: center;
}

.captureText {
  color: $motion-primary;
}

.disabledText {
  color: $text-primary;
  opacity: 0.25;
}

.mainButtonRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  width: 100%;
}

/* Action Menu */
.mainButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: $motion-primary;
  outline: none;
  border: none;
  transition: background-color 0.2s;

  border-radius: 100%;
  width: $main-button-size;
  height: $main-button-size;
  box-shadow: 0 0 0 4px $motion-transparent;
}

.mainButton:hover {
  background: $extensions-primary;
  box-shadow: 0 0 0 6px $motion-transparent;
}

.mainButton:disabled {
  background: $text-primary;
  border-color: $ui-black-transparent;
  box-shadow: none;
  opacity: 0.25;
}

.mainIcon {
  width: calc(#{$main-button-size} - 1rem);
  height: calc(#{$main-button-size} - 1rem);
}

.buttonRow {
  font-weight: bolder;
  text-align: right;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 480px;
}

.buttonRow button {
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  background: $ui-white;
  border: 1px solid $ui-black-transparent;
  font-weight: 600;
  font-size: 0.85rem;
  color: $motion-primary;
  cursor: pointer;
}

.buttonRow button.okButton {
  background: $motion-primary;
  border: $motion-primary;
  color: $ui-white;
}

[dir="rtl"] .retakeButton img {
  transform: scaleX(-1);
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.flashOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $ui-white;
  animation-name: flash;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* Leave at 0 opacity after animation */
}
