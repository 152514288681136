@import "css/colors.scss";
@import "css/units.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.spriteSelector {
  flex-grow: 1;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-right: calc(#{$space} / 2);
  border-top-right-radius: $space;
  border-top-left-radius: $space;
  border-color: $ui-black-transparent;
  border-width: 1px;
  border-style: solid;
  border-bottom: 0;

  @include theme {
    background-color: getVar("primary_background_color");
  }
}

.spriteWrapper {
  /*
        Our goal is to fit sprites evenly in a row without leftover space.
        Flexbox's `space between` property gets us close, but doesn't flow
        well when the # of items per row > 1 and less than the max per row.

        Solving by explicitly calc'ing the width of each sprite. Setting
        `border-box` simplifies things, because content, padding and
        border-width all are included in the width, leaving us only to subtract
        the left + right margins.

        @todo: make room for the scrollbar
    */
  box-sizing: border-box;
  width: 78px;
  max-width: 6rem;
  min-width: 4rem;
  min-height: 78px;
  /* @todo: calc height same as width */
  margin: 5px;
}

.sprite {
  height: 100%;
}


.scrollWrapper {
  /*
       Sets the sprite-selector items as a scrollable pane

        @todo: Safari: pane doesn't stretch to fill height;
        @todo: Adding `position: relative` still doesn't fix Safari scrolling pane, and
              also introduces a new bug in Chrome when vertically resizing window down,
              then back up, introduces white space in the outside the page container.
    */
  height: calc(100% - #{$sprite-info-height});
  overflow-y: auto;
}

.scrollWrapperDragging {
  background-color: $drop-highlight;
}

.itemsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: calc(#{$space} / 2);
  padding-left: calc(#{$space} / 2);
  padding-right: calc(#{$space} / 2);
  padding-bottom: 8px;
  max-height: 100%;
}

.addButton {
  position: absolute !important;
  bottom: 0.75rem;
}

[dir="ltr"] .addButton {
  right: 1rem;
}

[dir="rtl"] .addButton {
  left: 1rem;
}

.raised {
  background-color: #cce1ff;
  transition: all 0.25s ease;
}

.raised:hover {
  background-color: $drop-highlight;
  transform: scale(1.05);
}

.raised:hover {
  animation-name: wiggle;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  background-color: $drop-highlight;
}

@keyframes wiggle {
  0% {
    transform: rotate(3deg) scale(1.05);
  }

  25% {
    transform: rotate(-3deg) scale(1.05);
  }

  50% {
    transform: rotate(5deg) scale(1.05);
  }

  75% {
    transform: rotate(-2deg) scale(1.05);
  }

  100% {
    transform: rotate(0deg) scale(1.05);
  }
}

.receivedBlocks {
  animation: glowing 250ms;
}

@keyframes glowing {
  10% {
    box-shadow: 0 0 10px #7fff1e;
  }

  90% {
    box-shadow: 0 0 10px #7fff1e;
  }

  100% {
    box-shadow: none;
  }
}

.placeholder>.sprite {
  background: black;
  filter: opacity(15%) brightness(0%);
}
