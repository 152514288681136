@import "css/colors.scss";
@import "css/units.scss";
@import "css/z-index.scss";
@import "css/theme";

$main-button-size: 2.75rem;
$more-button-size: 2.25rem;

.menuContainer {
  display: flex;
  flex-direction: column-reverse;
  transition: 0.2s;
  position: relative;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  @include theme {
    background: getVar("primary_color")
  }
  outline: none;
  border: none;
}

button::-moz-focus-inner {
  border: 0;
}

.button:hover {
  @include theme {
    background-color: getVar("primary_hover_color");
    box-shadow: 0 0 0 4px getVar("primary_hover_color");
  }
}

.button:active {
  padding: inherit;
}

.button.coming-soon:hover {

  // background: $data-primary;
  @include theme {
    background-color: getVar("primary_color");
  }
}

.mainButton {
  border-radius: 100%;
  width: $main-button-size;
  height: $main-button-size;

  @include theme {
    box-shadow: 0 0 0 4px getVar("primary_box_shadow_color");
  }

  z-index: $z-index-add-button;
  transition: transform,
  box-shadow 0.5s;
}

.mainButton:hover {
  transform: scale(1.1);

  @include theme {
    box-shadow: 0 0 0 6px getVar("primary_box_shadow_color");
  }
}

.mainIcon {
  width: calc(#{$main-button-size} - 1rem);
  height: calc(#{$main-button-size} - 1rem);
}

[dir="rtl"] .mainIcon {
  transform: scaleX(-1);
}

.moreButtonsOuter {
  overflow-y: hidden;

  @include theme {
    background: getVar("primary_color");
  }
  border-top-left-radius: $more-button-size;
  border-top-right-radius: $more-button-size;
  width: $more-button-size;
  margin-left: calc((#{$main-button-size} - #{$more-button-size}) / 2);
  margin-right: calc((#{$main-button-size} - #{$more-button-size}) / 2);

  position: absolute;
  bottom: calc(#{$main-button-size});

  margin-bottom: calc(#{$main-button-size} / -2);
  padding-bottom: calc(#{$main-button-size} / 2);
}

.moreButtons {
  max-height: 0;
  transition: max-height 1s;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.fileInput {
  display: none;
}

.expanded .moreButtons {
  max-height: 1000px;
}

.force-hidden .moreButtons {
  display: none;
}

.moreButtons:first-child {
  border-top-right-radius: $more-button-size;
  border-top-left-radius: $more-button-size;
}

.moreButton {
  width: $more-button-size;
  height: $more-button-size;

  @include theme {
    background: getVar("primary_color");
  }
}

.moreIcon {
  width: calc(#{$more-button-size} - 1rem);
  height: calc(#{$more-button-size} - 1rem);
}

.comingSoon .moreIcon {
  opacity: 0.5;
}

/*
    @todo needs to be refactored with coming soon tooltip overrides.
    The "!important"s are for the same reason as with coming soon, the library
    is not very easy to style.
*/
.tooltip {
  @include theme {
    background: getVar("primary_color");
  }

  opacity: 1 !important;
  border: 1px solid hsla(0, 0%, 0%, .1) !important;
  box-shadow: 0 0 .5rem hsla(0, 0%, 0%, .25) !important;
  font-family: "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif !important;
}

.tooltip:after {
  @include theme {
    background-color: getVar("primary_color") !important;
  }
}

.comingSoonTooltip {
  @include theme {
    background-color: getVar("primary_color") !important;
  }
}

.comingSoonTooltip:after {
  @include theme {
    background-color: getVar("primary_color") !important;
  }
}

.tooltip {
  border: 1px solid hsla(0, 0%, 0%, .1) !important;
  border-radius: $form-radius !important;
  box-shadow: 0 0 .5rem hsla(0, 0%, 0%, .25) !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  z-index: $z-index-tooltip !important;
}

$arrow-size: 0.5rem;
$arrow-inset: -0.25rem;
$arrow-rounding: 0.125rem;

.tooltip:after {
  content: "";
  border-top: 1px solid hsla(0, 0%, 0%, .1) !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  border-right: 1px solid hsla(0, 0%, 0%, .1) !important;
  border-radius: $arrow-rounding;
  height: $arrow-size !important;
  width: $arrow-size !important;
}

.tooltip:global(.placeLeft):after {
  margin-top: $arrow-inset !important;
  right: $arrow-inset !important;
  transform: rotate(45deg) !important;
}

.tooltip:global(.placeRight):after {
  margin-top: $arrow-inset !important;
  left: $arrow-inset !important;
  transform: rotate(-135deg) !important;
}

.tooltip:global(.placeTop):after {
  margin-right: $arrow-inset !important;
  bottom: $arrow-inset !important;
  transform: rotate(135deg) !important;
}

.tooltip:global(.placeBottom):after {
  margin-left: $arrow-inset !important;
  top: $arrow-inset !important;
  transform: rotate(-45deg) !important;
}
