@import "css/colors.scss";

$border-radius: 4px;
$trim-handle-width: 30px;
$trim-handle-height: 30px;
$trim-handle-border: 3px;
$stripe-size: 10px;
$hover-scale: 1.25;

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* Force the browser to paint separately to avoid composite cost with waveform */
    transform: translateZ(0);
}

.selector {
    cursor: pointer;
}

.trimBackground {
    cursor: pointer;
    touch-action: none;
}

.trimBackgroundMask {
    border: 1px solid $red-tertiary;
    opacity: 0.5;

    background: repeating-linear-gradient(
        45deg,
        $red-primary,
        $red-primary $stripe-size,
        $red-tertiary $stripe-size,
        $red-tertiary calc(2 * #{$stripe-size})
    );
}

.selectionBackground {
    background: $looks-secondary;
    opacity: 0.5;
}

.startTrimBackground .trimBackgroundMask {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.endTrimBackground .trimBackgroundMask {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.trimLine {
    position: absolute;
    top: 0;
    width: 0px;
    height: 100%;
    border: 1px solid $red-tertiary;
}

.selector .trimLine {
    border: 1px solid $looks-secondary;
}

.playheadContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.playhead {
    /*
        Even though playhead is just a line, it is 100% width (the width of the waveform)
        so that we can use transform: translateX() using percentages.
    */
    width: 100%;
    height: 100%;
    border-left: 1px solid $looks-secondary;
    border-top: none;
    border-bottom: none;
    border-right: none;
}

.rightHandle {
    transform: scaleX(-1);
}

.selector .leftHandle {
    left: -1px
}

.selector .rightHandle {
    right: -1px
}

.trimmer .leftHandle {
    right: -1px
}

.trimmer .rightHandle {
    left: -1px
}

.trimHandle {
    position: absolute;
    width: $trim-handle-width;
    height: $trim-handle-height;
    right: 0;
    user-select: none;
}

.trimmer .trimHandle {
    filter: hue-rotate(-240deg) brightness(1.35);
}

.trimHandle img {
    position: absolute;
    width: $trim-handle-width;
    height: $trim-handle-height;
    left: calc(#{$trim-handle-border} * 1.5);

    /* Make sure image dragging isn't triggered */
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none; /* Autoprefixer doesn't seem to work for this */

    transition: 0.2s;
}

.topTrimHandle {
    top: calc(#{-$trim-handle-height} + #{$trim-handle-border});
}

.bottomTrimHandle {
    bottom: calc(#{-$trim-handle-height} + #{$trim-handle-border});
}

.topTrimHandle img {
    transform: scaleY(-1);
}
