@import "css/theme.scss";

.facePop {
  border-radius: 3px;
  background: rgb(255, 255, 255);
  padding: 5px;
  box-shadow: 0 0 2px 1px #C1C1C1;
  cursor: -webkit-grab;
}

.centerPop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4444;
}

.section {
  width: 480px;
  min-height: 103px;
  max-height: 403px;
  cursor: default;
  margin-top: 10px;
}

.section canvas {
  border-radius: 0px;
}

.section img {
  border-radius: 5px;
  max-width: 480px;
  max-height: 360px;
  pointer-events: none;
  cursor: default;
}

.right_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 500;
}

.icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 15px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  transition: .2s;
}

.icon img {
  max-width: 100%;
}

.icon:hover {
  box-shadow: 0 0px 0px 3px #f1eded9c;
  transform: scale(1.2);
}


.section>div {
  text-align: left;
  margin: 0 auto;
}

.section>div>span {
  display: inline-block;
  position: relative;
}

.section a {
  text-decoration: none;
}

.resultText {
  margin: 0 0 0 10px;
  padding: 10px;
  border-radius: 13px;
  font-size: 15px;
  line-height: 25px;
  flex: 2;

  @include theme {
    border: 3px solid getVar("primary_color");
  }
}

.section input[type='file'] {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pictureAI_title {
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  display: block;
  color: #fff;
  line-height: 26px;
  width: calc(100% + 10px);
  cursor: pointer;
  margin-left: -5px;
  text-indent: 20px;
  padding: 8px;
  color: #fff;
  margin-top: -8px;
  pointer-events: none;
  border-radius: 3px 3px 0 0;

  @include theme {
    background: getVar("primary_color");
  }
}

.download {
  position: absolute;
  right: 4%;
  bottom: 4%;
}

.download img {
  width: 24px;
}

.toggleCamera {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 25px;
  right: 15px;
  opacity: .8;
  cursor: pointer;
}

.toggleCamera:hover {
  opacity: 0.5;
}

.modalHead {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.modalBody {
  overflow: hidden;
  text-align: center;
  position: relative;
}

.camLists {
  position: absolute;
  font-size: 14px;
  border-radius: 10px;
  overflow-y: auto;
  width: 250px;
  max-height: 200px;
  cursor: pointer;
  overflow-x: hidden;
  right: 13px;
  top: 56px;
}

.camLists_item {
  background: #fff;
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  line-height: 45px;
  height: 45px;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.camLists_item:hover {
  background: #F5F5F5;
}


.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-radius {
  border-radius: 4px !important;
  overflow: hidden;
}

.scaleImg {
  position: absolute;
  top: 0;
  left: calc(-100% - 5px);
  border-radius: 5px;
  opacity: 1;
  transition: .5s;
  display: block;
  width: 100%;
}

.scaleImg0 {
  opacity: 0;
  width: 0;
  display: none;
}
