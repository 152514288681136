@import "css/theme.scss";

.message {
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
  z-index: 999999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: move 0.5s linear;
  position: relative;
  font-size: 14px;
  display: flex;
  padding: 9px 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
  gap: 6px;

  .success {
    @include theme {
      color: getVar("success_color");
    }

    font-size: 16px;
  }

  .info {
    @include theme {
      color: getVar("info_color");
    }

    font-size: 16px;
  }

  .warning {
    @include theme {
      color: getVar("warning_color");
    }

    font-size: 16px;
  }

  .error {
    @include theme {
      color: getVar("error_color");
    }

    font-size: 16px;
  }

  .loading {
    font-size: 16px;
  }

  &.visible {
    opacity: 1;
  }

  .content {
    flex: 1;
  }

  .closeButton {
    cursor: pointer;
    margin-left: 12px;
  }
}

@keyframes move {
  0% {
    top: 0;
  }

  50% {
    top: 10px;
  }

  90% {
    top: -5px;
  }

  100% {
    top: 0px;
  }
}
