@import "css/colors.scss";
@import "css/units.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.contextMenu {
  min-width: 130px;
  padding: 5px 0; /* The white strip at the top and bottom of the menu */
  margin: 2px 0 0; /* To keep the menu below the cursor comfortably */
  font-size: 0.85rem;
  text-align: left;
  background-color: $ui-white;
  border: 1px solid $ui-black-transparent;
  border-radius: calc(#{$space} / 2);
  box-shadow: 0px 0px 5px 1px $ui-black-transparent;
  pointer-events: none;
  transition: opacity 0.2s ease;
  z-index: $z-index-context-menu;
}

.menuItem {
  padding: 8px 12px;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.1s ease;
}

.menuItem:hover {

  color: white;
  @include theme {
    background: getVar("primary_color")
  }
}

.menuItemBordered {
  border-top: 1px solid $ui-black-transparent;
}

.menuItemDanger:hover {
    @include theme {
        background: getVar("error_color")
    }
}
