@import "css/theme";

.container {
  @include theme {
    color: getVar("primary_color");
  }
  font-size: 16px;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: -2px;
}
