@import "css/colors.scss";

.progressRing {
    margin: 0.5rem;
}

.progressRingSvg {
    fill: transparent;
}

.progressRing .progressRingRing {
    stroke: $looks-light-transparent;
}

.progressRing .progressRingValue {
    stroke: $looks-secondary;
    stroke-linecap: round;
}
