.paint {
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  >div {
    box-sizing: border-box;
  }
}
