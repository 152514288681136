@import "css/colors.scss";

.buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.iconButton {
    margin: 0.25rem;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    user-select: none;
}

.iconButton:active > img {
    width: 20px;
    height: 20px;
    transform: scale(1.15);
}

.iconButton > img {
    transition: transform 0.1s;
    filter: grayscale(100%);
}

.iconButton.active > img {
    filter: none;
}
