@import "css/theme.scss";

@mixin buttonStyle {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  cursor: pointer;

  .button {
    @include buttonStyle;
  }

  .leftActive {
    @include buttonStyle;

    @include theme {
      border: 1px solid getVar("primary_color");
      background-color: getVar("primary_background_color");
    }

    border-radius: 4px 0 0 4px;
  }

  .rightActive {
    @include buttonStyle;

    @include theme {
      border: 1px solid getVar("primary_color");
      background-color: getVar("primary_background_color");
    }

    border-radius: 0 4px 4px 0;
  }

  .left {
    @extend .leftActive;

    svg, i {
      @include theme {
        color: getVar("border_color");
      }
    }

    border-right: none !important;

    @include theme {
      border-color: getVar("border_color");
      background-color: getVar("container_bg_color");
    }

    &:hover {
      @include theme {
        border-color: getVar("primary_border_hover_color")
      }
    }
  }

  .right {
    @extend .rightActive;

    svg, i {
      @include theme {
        color: getVar("border_color");
      }
    }

    border-left: none !important;

    @include theme {
      border-color: getVar("border_color");
      background-color: getVar("container_bg_color");
    }

    &:hover {
      @include theme {
        border-color: getVar("primary_border_hover_color")
      }
    }
  }
}
